.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  width: 100%;
}

/* Заголовок */
.header {
  padding: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.meta {
  font-size: 14px;
}

/* Контент */
.content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  background: #232323;
  overflow-x: hidden;
  width: 100%;
}

/* Загрузка и ошибки */
.loading {
  padding: 20px;
  font-size: 16px;
}

.error {
  padding: 20px;
  color: red;
  font-size: 16px;
}
